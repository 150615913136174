export const allowlistAddresses = [
    ["0xf82ed98ad6b0a92bb1195ae3d2618b8adf2fcc35",1000],
    ["0x5c50d396e7cdc2be26869831bb420df3b1f0202a",1001],
    ["0x457511f218ff7231fa69419d713e9ba714b4b3ee",1002],
    ["0x136f51fe8219d8b766501ef00c61d36f3e1141fd",1003],
    ["0xbdfac522aa64ff8fcb1ced7620478cb7bafa1ea3",1004],
    ["0x55b239003197ec0f57b6acc1208503f9cdeb1c4d",1005],
    ["0xae62600d0eb545c445ced4c239a4e297f0c20f2e",1006],
    ["0x58fe2163a2a538d12a9ba95bba46ae078a4703eb",1007],
    ["0x870892a8b26f6c86d3179d7918e5bd8eefc17563",1008],
    ["0x92899a06be869e865dbef4e115f8f46c58f15e74",1009],
    ["0x75c9a8e526bd78e99a5394a3d7440d312e9429cd",1010],
    ["0xb577575a51927c83c9a30087dac9329b5d1c4571",1011],
    ["0xb8c2e9fad29e4bbda3832bf8d3300727bc22a70d",1012],
    ["0x9e48a722a8b0fac1f140d6fafc9c7167d34a7891",1013],
    ["0x327fe16d7c20ac32aeaab2ecbfc0ed6b37e05e0e",1014],
    ["0x71a5a3aa039fb63dba8ce2e3c3a3e2dab81a5939",1015],
    ["0x7936910b8a03bf9ac7bcef3bb3efbb417a41f0ba",1016],
    ["0x40b337b3997b9f15d824087c86216aa04033be09",1017],
    ["0xc8b6aa7e3ae7f7c4efb90987dea8c6ccb63d7845",1018],
    ["0xac25ab3dadd397a2c15aeba5e48ad28dd8f02032",1019],
    ["0xaf7554576ca7aeb8c5804534ce5253c518cd0ad2",1020],
    ["0x09b03731610c4ed8e697ab65859e2e6a6ed2a031",1021],
    ["0x288f4d139ca67c2124456842696572acc1c647cd",1022],
    ["0xe03d062de0a0129fad81e1af6f971acd588ed22f",1023],
    ["0xf6e0c3fac7cf44b92a5420b1658ff043ea879bdf",1024],
    ["0x16343d614134e66e6898b5aeecc3fd045a617a20",1025],
    ["0xeb6df4615702c22d78c148a10e4397b80a5184d2",1026],
    ["0x4ad5df8bfa6de0d1d97c494f2ff4898359e73c12",1027],
    ["0xaadaa45b066a54252a8c5c9b6e434e42de96f607",1028],
    ["0x8ccb0089578598be538645190408411a256d3fbf",1029],
    ["0x5243bde21b1bfa9bae695b3dd1e6346aed8d4a30",1030],
    ["0x5964e73355f9c44e01175bd868da4c81aa018b41",1031],
    ["0x0b1f309fbd3d038576df6d205bc6c6c13ebbe3b6",1032],
    ["0x98b1d1243fb7edce3d7427ccc88d3b0ac122b8c4",1033],
]